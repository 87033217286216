/* 
┏━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┓
┃ @소스코드: 정의 명세서                             ┃
┣━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┫
┣ @설명: Footer 컴포넌트
┣ @작성: 신우균, 2021-01-20                        
┣ @내역: 신우균, 2021-01-20, 최초등록
┗━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┛
*/
<template>
    <div class="footer">
        <div class="footer__container">
            <div class="footer__brand">
                <b-img 
                    class="logo"
                    src="~@/assets/imgs/footer/sctc_logo.png"
                ></b-img>
            </div>
            <div class="footer__content">
                <ul>
                    <li>세종특별자치시 조치원읍 군청로 93 조치원청사 A동</li>
                    <li>사업자등록번호: 133-82-04414 , 대표자 도순구</li>
                    <li>고객센터: 1899-9161</li>
                    <a href="https://www.sctc.kr/page/PAGE1612081600364372"><li>개인정보처리방침</li></a>

                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
    .footer {
        height: 140px;
        background: #E0E0E0;

        &__container {
            max-width: 1024px;
            height: 100%;
            display: flex;
            align-items: center;
            margin: 0 auto;
        }   
        &__brand {
            margin-left: 20px;
        }
        &__content {
            padding-left: 30px;
            ul { 
                margin-bottom: 0px;
                li { 
                    font-size: 1.4rem; 
                    margin: 0px; 
                    list-style: none;
                }
            }
        }
    }
    .logo {
        width: 200px;
        height: 44px;
    }

@include media(mobile) {
    .footer {
        height: 160px;
        &__container {
            display: block;
        }
        &__brand {
            padding-top: 20px;
        }
        &__content {
            padding-left: 0px;
            padding-top: 10px;
        }
    }
}
</style>