/* 
┏━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┓
┃ @소스코드: 정의 명세서                             ┃
┣━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┫
┣ @설명: 네비바(Navbar) 컴포넌트     
┣ @작성: 신우균, 2021-01-20                        
┣ @내역: 신우균, 2021-01-20, 최초등록
┗━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┛
*/
<template>
    <div>
        <b-navbar class="navbar" toggleable="xl">
            <div class="navbar__container--desktop">
                <b-navbar-brand>
                    <router-link to="/">
                        <b-img
                            class="navbar__logo"
                            src="~@/assets/imgs/navbar/logo.jpg"
                            alt="logo"
                        ></b-img>
                    </router-link>
                </b-navbar-brand>
                <div>
                    <div class="navbar__item">
                        <b-img class="navbar__item-icon" src="~@/assets/imgs/navbar/bike.svg"></b-img>
                        <h4 class="navbar__item-title">어울링이란</h4>
                        <ul class="navbar__item-subitem">
                            <li><router-link to="/eouling/introduce" tag="li">어울링소개</router-link></li>
                            <li><router-link to="/eouling/service" tag="li">서비스안내</router-link></li>
                            <li><router-link to="/eouling/duty" tag="li">의무와 책임</router-link></li>
                            <li><router-link to="/eouling/insurance" tag="li">시민자전거보험</router-link></li>
                        </ul>
                    </div>
                    <div class="navbar__item">
                        <b-img class="navbar__item-icon" src="~@/assets/imgs/navbar/information.svg"></b-img>
                        <h4 class="navbar__item-title">이용안내</h4>
                        <ul class="navbar__item-subitem">
                            <li><router-link to="/usage/app" tag="li">어울링 앱소개</router-link></li>
                            <li><router-link to="/usage/bikeUsage" tag="li">자전거 이용정보</router-link></li>
                        </ul>
                    </div>
                    <div class="navbar__item" @click="$router.push('/stationSearch')">
                        <b-img class="navbar__item-icon" src="~@/assets/imgs/navbar/map.svg"></b-img>
                        <h4 class="navbar__item-title" to="/stationSearch">대여소 조회</h4>
                    </div>
                    <div class="navbar__item">
                        <b-img class="navbar__item-icon" src="~@/assets/imgs/navbar/headset.svg"></b-img>
                        <h4 class="navbar__item-title">고객센터</h4>
                        <ul class="navbar__item-subitem">
                            <li><router-link to="/customer/notice" tag="li">공지사항</router-link></li>
                            <li><router-link to="/customer/faq" tag="li">자주하는질문</router-link></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="navbar__container--mobile">
                <div class="navbar__item">
                    <div class="navbar__item__left"></div>
                    <b-navbar-brand>
                        <router-link to="/">
                            <b-img
                                class="navbar__logo"
                                src="~@/assets/imgs/navbar/logo.jpg"
                                alt="logo"
                            ></b-img>
                        </router-link>
                    </b-navbar-brand>
                    <b-navbar-toggle 
                        v-model="drawerToggle"
                        target="nav-collapse"
                        class="navbar__item__right" 
                    ></b-navbar-toggle>
                </div>
            </div>
            <b-collapse v-model="drawerToggle" class="navbar__drawer" id="nav-collapse">
                <div class="drawer__item-container">
                    <div class="drawer__item" @click="movePage('/eouling/introduce')">
                        <b-img class="drawer__item-icon" src="~@/assets/imgs/navbar/bike.svg"></b-img>
                        <h4 class="drawer__item-title">어울링이란</h4>
                    </div>
                    <div class="drawer__item" @click="movePage('/usage/app')">
                        <b-img class="drawer__item-icon" src="~@/assets/imgs/navbar/information.svg"></b-img>
                        <h4 class="drawer__item-title">이용안내</h4>
                    </div>
                    <div class="drawer__item" @click="movePage('/stationSearch')">
                        <b-img class="drawer__item-icon" src="~@/assets/imgs/navbar/map.svg"></b-img>
                        <h4 class="drawer__item-title">대여소 조회</h4>
                    </div>
                    <div class="drawer__item" @click="movePage('/customer/notice')">
                        <b-img class="drawer__item-icon" src="~@/assets/imgs/navbar/headset.svg"></b-img>
                        <h4 class="drawer__item-title">고객센터</h4>
                    </div>
                </div>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
export default {
    data() {
        return {
            drawerToggle: false,
        }
    },
    methods: {
         /**********************************************************************
         *  @method     : 페이지 이동
         *  @param      { string }      :router path
         *  @returns    { undefined }
         *********************************************************************/
        movePage(value) {
            this.drawerToggle = false;
            this.$router.push(value);
        }
    }
}
</script>

<style lang="scss" scoped>
    .navbar {
        position: relative;
        z-index: 1000;  
        box-shadow: 0 2px 3px #6d6c6c;
        cursor: pointer;
    }
    @include media(mobile, tablet) {
        .navbar {
            height: 60px;
            padding-left: 0px;
            padding-right: 0px;
            &__logo { height: 50px; }
            &__container--desktop { display: none; }
            &__container--mobile { width: 100%; }
            &__item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0px 10px;
                &__left { width: 50px; }
                &__right { width: 50px; }
            }
            &__drawer {
                width: 100%;
                background: #fff;
                .drawer__item-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: wrap;
                    padding-bottom: 50px;
                    padding-top: 50px;
                }
                .drawer__item {
                    width: 120px;
                    border: 1px solid #000;
                    margin: 10px;
                    padding: 10px 1px;
                    border-radius: 10px;
                    text-align: center;

                    &-title { font-size: 1.6rem; }
                    &-icon {
                        width: 50px;
                        height: 50px;
                    }
                }
            }
        }
    }
    @include media(desktop) {
        .navbar { 
            height: 100px;
            &__logo { width: 150px; height: 70px; }
            &__drawer { display: none; }
            &__container--mobile { display: none; }
            &__container--desktop {
                min-width: 1024px;
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 0 auto;
                padding: 0px;
            }
            &__item {
                width: 150px;
                height: 80px;
                display: inline-block;
                position: relative;
                padding-top: 10px;
                text-align: center;
                &:hover {
                    background: #CDDC39;
                    .navbar__item-subitem { 
                        display: block; 
                    }
                }
                &-icon {
                    width: 35px;
                    height: 35px;
                    margin-bottom: 5px;
                }
                &-title { 
                    font-size: 2.0rem; 
                };
                &-subitem {
                    width: 150px;
                    display: none;
                    position: absolute;
                    list-style: none;
                    padding-left:0px;
                    background: #eee;
                    // max-height: 0;
                    // transition: max-height 0.2s ease-in; 
                    // overflow: hidden;
                    & li {
                        padding: 3px 0px;
                        font-size: 1.6rem;
                        &:hover {
                            background: #fff;
                        }
                    }
                }
            }
        }
    }
</style>
